<template>
 <div
  class="bg-white px-4 py-3 flex flex-wrap items-center justify-between border-t border-gray-200 sm:px-6"
 >
  <div class="flex flex-1 justify-between lg:hidden">
   <a
    href="javascript:void(0)"
    @click="$emit('previousPage')"
    class="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
   >
    {{ $t("ticketTable.previous") }}
   </a>
   <a
    href="javascript:void(0)"
    @click="$emit('nextPage')"
    class="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
   >
    {{ $t("ticketTable.next") }}
   </a>
  </div>
  <div class="hidden lg:flex lg:flex-1 lg:items-center lg:justify-between">
   <div class="hidden lg:block">
    <p class="text-sm text-gray-700">
     {{ $t("ticketTable.showing") }}
     {{ " " }}
     <span class="font-medium">
      {{ from }}
     </span>
     {{ " " }}
     {{ $t("ticketTable.to") }}
     {{ " " }}
     <span class="font-medium">{{ to }}</span>
     {{ " " }}
     {{ $t("ticketTable.of") }}
     {{ " " }}
     <span class="font-medium">{{ total }}</span>
     {{ " " }}
     {{ $t("ticketTable.results") }}
    </p>
   </div>

   <!-- <div class="lg:px-5">
    <select
     @change="$emit('setRows', $event.target.value)"
     id="location"
     name="location"
     class="rounded mt-1 block pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none sm:text-sm"
    >
     <option :value="rows">{{ $t("ticketTable.selectRows") }}</option>
     <option v-for="numb in select_rows" :key="numb" :value="numb">
      {{ numb }} {{ $t("ticketTable.rows") }}
     </option>
     <option :value="total">{{ $t("ticketTable.showAll") }}</option>
    </select>
   </div> -->

   <div class="flex-1 flex sm:justify-end">
    <nav
     v-if="backend_pagination"
     class="isolate inline-flex -space-x-px rounded-md shadow-sm"
     aria-label="Pagination"
    >
     <button
      v-for="(link, idx) in links"
      :key="idx"
      @click="link.url && $emit('action', link.url)"
      :class="[
       link.label.includes('Précédent')
        ? 'rounded-l-md px-2 text-gray-400 hover:bg-gray-50'
        : link.label.includes('Suivant')
        ? 'rounded-r-md px-2 text-gray-400 hover:bg-gray-50'
        : link.active
        ? 'z-10 encom_primary text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600'
        : 'bg-white border-gray-300 text-gray-900 hover:bg-gray-50',
       ,
       'cursor-pointer relative inline-flex items-center px-2 py-2 ring-1 ring-inset ring-gray-300 bg-white text-sm font-semibold',
      ]"
     >
      <span v-show="link.label.includes('Précédent')">
       <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        class="w-5 h-5"
       >
        <path
         fill-rule="evenodd"
         d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
         clip-rule="evenodd"
        />
       </svg>
      </span>
      <span v-show="parseInt(link.label)"> {{ link.label }} </span>
      <span v-show="link.label.includes('Suivant')">
       <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        class="w-5 h-5"
       >
        <path
         fill-rule="evenodd"
         d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
         clip-rule="evenodd"
        />
       </svg>
      </span>
     </button>
    </nav>
    <nav v-else class="isolate inline-flex rounded-md" aria-label="Pagination">
     <a
      href="javascript:void(0)"
      @click="$emit('previousPage')"
      class="relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
     >
      {{ $t("ticketTable.previous") }}
     </a>
     <a
      href="javascript:void(0)"
      @click="$emit('nextPage')"
      class="relative ml-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
     >
      {{ $t("ticketTable.next") }}
     </a>
    </nav>
   </div>
  </div>
 </div>
</template>

<script>
export default {
 name: "TableComponent",
 props: {
  backend_pagination: {
   type: Boolean,
   required: true,
  },
  current_page: {
   type: Number,
   required: true,
  },
  first_page_url: {
   type: String,
   required: true,
  },
  from: {
   type: Number,
   required: true,
  },
  last_page: {
   type: Number,
   required: true,
  },
  last_page_url: {
   type: String,
   required: true,
  },
  links: {
   type: Array,
   required: true,
  },
  next_page_url: {
   type: String,
   required: true,
  },
  per_page: {
   type: Number,
   required: true,
  },
  to: {
   type: Number,
   required: true,
  },
  total: {
   type: Number,
   required: true,
  },
  select_rows: {
   type: Array,
   required: false,
  },
  rows: {
   type: Number,
   required: false,
  },
 },
 emits: ["action"],
};
</script>

<style></style>
