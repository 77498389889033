<template>
 <div class="text-center border-dashed border-2 border-gray-300 rounded mx-5 p-10">
  <svg
   xmlns="http://www.w3.org/2000/svg"
   fill="none"
   viewBox="0 0 24 24"
   stroke-width="1.5"
   stroke="currentColor"
   class="mx-auto h-10 w-10 text-gray-400"
  >
   <path
    stroke-linecap="round"
    stroke-linejoin="round"
    d="M3.75 9.776c.112-.017.227-.026.344-.026h15.812c.117 0 .232.009.344.026m-16.5 0a2.25 2.25 0 00-1.883 2.542l.857 6a2.25 2.25 0 002.227 1.932H19.05a2.25 2.25 0 002.227-1.932l.857-6a2.25 2.25 0 00-1.883-2.542m-16.5 0V6A2.25 2.25 0 016 3.75h3.879a1.5 1.5 0 011.06.44l2.122 2.12a1.5 1.5 0 001.06.44H18A2.25 2.25 0 0120.25 9v.776"
   />
  </svg>

  <h3 class="mt-2 text-sm font-semibold text-gray-900">
   {{ title }}
  </h3>
  <p class="mt-1 text-sm text-gray-500">{{ subtitle }}</p>
  <div v-if="hasActionButton" class="mt-6">
   <button
    type="button"
    class="inline-flex items-center shadow-sm text-white rounded-md px-3 py-2 sm:text-sm body__header--button font-semibold mr-0"
   >
    <svg
     xmlns="http://www.w3.org/2000/svg"
     viewBox="0 0 20 20"
     fill="currentColor"
     class="-ml-0.5 mr-1.5 h-5 w-5"
    >
     <path
      d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z"
     />
    </svg>

    {{ buttonTitle }}
   </button>
  </div>
 </div>
</template>

<script>
export default {
 name: "EmptyState",
 props: {
  title: {
   type: String,
   required: true,
  },
  subtitle: {
   type: String,
   required: true,
  },

  hasActionButton: {
   type: Boolean,
   required: true,
  },
  buttonTitle: {
   type: String,
   required: false,
  },
 },
};
</script>

<style></style>
